import * as React from "react"

const Catalogo = () => {
    return(
        <div className="catalog-container">
            <a href="/catalogo" className="catalogo">
                <h2>Inovação e Tecnologia – qualidade igual ao original</h2>
                <button>Veja nosso catálogo</button>
            </a>
        </div>
    )
}

export default Catalogo