import React from 'react';
import certificate from '../../assets/images/certificate-wega.png'

const Policy = () => {
    return(
        <section className="container-policy">
            <div className="policy-title">
                <h2 className="title">POLÍTICA DA QUALIDADE WEGA</h2>
            </div>
            <div className="policy-content">
                <p>Desde 2002, a Wega Motors é uma empresa especializada no mercado automotivo. Que atua na comercialização de autopeças, para veículos leves, pesados, comerciais, motos, tratores, entre outros nacionais e importados. Vem conquistando ao longo dos anos o maior portfólio do mercado na linha de filtros. Sendo assim, define como sua política do sistema de gestão:</p>
            </div>

            <div>
                <ul className="policy-list">
                    <li>Oferecer os melhores produtos para nossos clientes, com integridade e transparência por meio de atendimento aos requisitos legais, respeitando a cadeia de distribuição;</li>
                    <li>Ser referência no mercado de autopeças;</li>
                    <li>Atendimento pós-venda (Garantia e equipe de “trade” no mercado nacional de reposição automotiva);</li>
                    <li>Oferecer um sistema de logística de ponta, visando a melhor qualidade de entrega e serviço ao cliente;</li>
                    <li>Promover melhoria no sistema de gestão;</li>
                    <li>Investir em tecnologia e inovação de produtos, visando sempre a preservação do meio ambiente, levando o conceito do descarte consciente dos filtros e produtos recicláveis;</li>
                    <li>Garantir a rentabilidade e o consequente retorno aos acionistas.</li>
                </ul>
            </div>

            <section className="certificate">
                <div>
                    <p className="certificate-content">A empresa possui certificação NBR ISO 9001:2015 no Brasil também:</p>
                </div>
                <div className="certificate-image">   
                    <img className src={certificate} />
                </div>  
            </section>
        </section>
    )
}

export default Policy;