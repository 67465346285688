import * as React from "react"

import Layout from "../components/Layout"
import Catalogo from "../components/Catalogo"
// import Contato from "../components/Contato"
import Seo from "../components/seo"
import Policy from '../components/Policy';

import defaults from "../components/dafaults"

const SecondPage = () => (
  <Layout>
    <section className="content about-container">
      <div className="about-content-title">
        <h1 className="title">QUEM SOMOS</h1>
      </div>
      <div className="insideContent">
        <div className="row row-1">
          <img src={`${defaults.wp.site}wp-content/uploads/2018/05/foto-Wega-01.png`} alt="Wega Motors - Quem somos" />
          <div className="text">
            <h2 className="title-row-1">SOMOS A WEGA MOTOS BRASIL</h2>
            <p>Em 1969, Rafael Neto fundou o Grupo Argentino R. Neto S.A, localizado em Buenos Aires. A organização possui três polos industriais em solo argentino, sede própria em Itajaí/SC e Centro Comercial em São Paulo. Sua moderna instalação produz uma ampla gama de produtos para o mercado de reparação automotiva na linha leve e pesada.</p>
          </div>
        </div>
        <div className="row reverse row-2">
          <img src={`${defaults.wp.site}wp-content/uploads/2018/05/foto-Wega-02.png`} alt="Wega Motors - Quem somos" />
          <div className="text ">
            <h2>Missão / Visão / Valores</h2>
            <strong>Missão</strong>
            <p>“Satisfazer plenamente as necessidades do mercado brasileiro, fornecendo peças automotivas de alta qualidade”.</p>

            <strong>Visão</strong>
            <p>“Ser uma empresa reconhecida no mercado, com base na ética e no crescimento sustentável e inovador”.</p>

            <p><strong>Valores</strong></p>
            <ul className="list">
              <li>Criar entusiasmo e satisfação no cliente.</li>
              <li>Trabalhar com Integridade e comprometimento.</li>
              <li>Promover o trabalho em equipe, gerando oportunidades.</li>
              <li>Estimular o espírito inovador.</li>
              <li>Estabelecer metas de melhoria contínua.</li>
              <li>Respeitar o indivíduo e a sociedade.</li>
              <li>Privilegiar atitudes e práticas sustentáveis.</li>
            </ul>
          </div>
        </div>

        <Policy />
        {/* <div className="row">
          <iframe width="528" height="500" src="https://www.youtube.com/embed/K8ZnKIi9SVg" title="Filtro de AR - Deve ser trocado? WEGA MOTORS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <Contato /> */}
        <Catalogo />
      </div>
    </section>
  </Layout>
)

export const Head = () => <Seo title="Page two" />

export default SecondPage
